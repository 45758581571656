import React from 'react';
import * as styles from "./default-subcategory.module.scss";

import { Link } from "gatsby";

import GsProductHeaderImage from "../page-components/product/gsProductHeaderImage";
import GsProductPrice from "../page-components/product/gsProductPrice";
import GsProductCompareAtPrice from "../page-components/product/gsProductCompareAtPrice";
import GsArrowLink from "../page-components/buttons/gsArrowLink";
import { gsCurrencyPipe } from "../helper/gsCurrencyPipe";

const DefaultSubcategoryProductCard = ( { p } ) => {
    return <div className={"col-xl-4 col-12 col-md-6 mb-4"} key={p.id}>

        <div className={styles.product + " h-100"}>
            <Link to={"/produkt/" + p.handle}>
                <div className="text-center mb-2">
                    <GsProductHeaderImage product={p} className={styles.productImg} />
                </div>
            </Link>

            <div className="p-4">
                <h5>{ p.title }</h5>

                {p.variants[0]?.compareAtPrice > 0 &&
                <p className={styles.comparePrice + " mt-3 mb-1"}>
                    statt {gsCurrencyPipe(p.variants[0].compareAtPrice)}
                </p>
                }

                <GsProductPrice product={p} />
                <GsProductCompareAtPrice product={p} className={"mb-3"} />

                <GsArrowLink className={"mt-3"} to={"/produkt/" + p.handle}>Mehr erfahren</GsArrowLink>
            </div>
        </div>

    </div>
};

export default DefaultSubcategoryProductCard;
